import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import { AiFillGithub, AiOutlineTwitter } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I'm a Senior Software Engineering Manager with 10+ years of
              experience
              <br />
              <br />
              leading and managing teams to build, ship, and
              operationalize&nbsp;
              <i>
                <b className="purple">
                  full stack software, cloud solutions, and SaaS products.
                </b>
              </i>
              <br />
              <br />
              My expertise spans across&nbsp;
              <i>
                <b className="purple">
                  full-stack development, cloud architecture, cybersecurity
                </b>
                &nbsp;and managing&nbsp;
                <b className="purple">distributed engineering teams.</b>
              </i>
              <br />
              <br />I hold multiple professional certifications in&nbsp;
              <b className="purple">AWS, GCP, Docker, Terraform, Agile</b> and
              oversee the&nbsp;
              <i>
                <b className="purple">managed cloud</b>&nbsp;and&nbsp;
                <b className="purple">cybersecurity product portfolio</b>
              </i>
              , driving the delivery of the roadmap.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/jyzhao"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/jiyuanzhao/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/voodoo_teddy"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
